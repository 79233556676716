import {
  CameraShake,
  ContactShadows,
  Environment,
  Html,
  OrbitControls,
  Plane,
  SpotLight,
} from "@react-three/drei";
import { Canvas, extend } from "@react-three/fiber";

import { RectAreaLight } from "lgl-tracer";
import React, {
  createRef,
  Suspense,
  useContext,
  useEffect,
  useRef,
} from "react";
import { DoubleSide, MathUtils } from "three";
import { VIEWER_HEIGHT } from "../../constants/layoutConstants";
import EditorContext, { TOOLS } from "../../context/EditorContext";
import { CornerRoom } from "../scenes/CornerRoom";

import { Elf } from "./Elf";
import LoadingPage from "./LoadingPage";

extend({ RectAreaLight });

const shakeArgs = {
  maxPitch: 0.05,
  maxRoll: 0.05,
  maxYaw: 0.05,
  pitchFrequency: 0.8,
  rollFrequency: 0.8,
  yawFrequency: 0.8,
}
let canvasRef = createRef();

const GRID_SIZE = 10,
  GRID_SEGMENTS = 32;

export default function WebGLViewerTest({ isRayTracingEnabled }) {
  useEffect(() => {
    var audio = new Audio("we-wish-you-a-merry-christmas.mp3");
    // audio.play();
  });
  const {
    activeTool,
    data: voxels,
    setData: setVoxels,
    color,
  } = useContext(EditorContext);
  const orbitRef = useRef(null);
  return (
    <Canvas
      gl={{ preserveDrawingBuffer: true, logarithmicDepthBuffer: true, toneMappingExposure: Math.pow(2, -5) }}
      dpr={1.5}
      camera={{ position: [8, 5, 3], far: 150 }}
      style={{
        height: "100vh",
        width: "100vw",
        zIndex: "0",
      }}
      mode="concurrent"
      ref={canvasRef}
      id="webgl-canvas"
    >
      {/* <SpotLight
        distance={50}
        intensity={500}
        angle={MathUtils.degToRad(90)}
        color={"green"}
        position={[0, 0, 0]}
      />
      // <SpotLight
      //   distance={50}
      //   intensity={80}
      //   angle={MathUtils.degToRad(90)}
      //   color={'#fadcb9'}
      //   position={[20, 30, 20]}
      // /> */}

      {/* Comment the following for normal mode  */}
      <ambientLight intensity={30} color={"red"} />
      <OrbitControls
        ref={orbitRef}
        makeDefault
        dampingFactor={0.2}
        enabled={
          activeTool !== TOOLS.RECTANGLE_ADD &&
          activeTool !== TOOLS.RECTANGLE_SUBTRACT
        }
        maxZoom={10}
        maxPolarAngle={Math.PI / 2}
        maxAzimuthAngle={Math.PI / 4}
        minAzimuthAngle={-Math.PI / 4}
        maxDistance={15}
        minDistance={1}
      />
      {/* Comment the following for normal mode  */}
      <CameraShake
        {...shakeArgs}
        controls={orbitRef}
      />
      <Scene
        voxels={voxels}
        setVoxels={setVoxels}
        activeTool={activeTool}
        color={color}
      />
      <Environment preset="studio" background={false} />
    </Canvas>
  );
}

function Scene() {
  // const gl = useThree((state) => state.gl);
  // const scene = useThree((state) => state.scene);
  // const camera = useThree((state) => state.camera);
  // const raycaster = useThree((state) => state.raycaster);
  // const rollOverMesh = useRef();
  const planeRef = useRef();
  const gridRef = useRef();
  const sphereRef = useRef();

  return (
    <>
      <mesh ref={sphereRef} name="sphere">
        <sphereBufferGeometry args={[5000]} />
        <meshStandardMaterial
          metalness={0}
          roughness={1}
          color="white"
          side={DoubleSide}
        />
      </mesh>
      {/* <gridHelper
        args={[GRID_SIZE, GRID_SEGMENTS, "#666666", "#666666"]}
        ref={gridRef}
      /> */}

      <Plane
        args={[1000, 1000]}
        rotation={[-Math.PI / 2, 0, 0]}
        ref={planeRef}
        visible={false}
        name="plane"
      >
        <meshBasicMaterial visible={false} opacity={0} color="blue" />
      </Plane>
      <Suspense
        fallback={
          <Html fullscreen={true} center={false}>
            <LoadingPage />
          </Html>
        }
      >
        <CornerRoom
          scale={20.0}
          rotation={[0, (Math.PI / 180) * -60, 0]}
          position={[0, -0.02, 25]}
        />
        <group position={[0, 0.01, 0]}>
          <Elf position={[5, 0, 0]} path="/derek.glb" />
          <Elf position={[0, 0, 0]} path="/sarah.glb" />
          <Elf position={[-5, 0, 0]} path="/todd.glb" />
        </group>
        <ContactShadows
          position={[0, -0.01, 0]}
          scale={11}
          far={3}
          blur={2}
          resolution={2024}
          rotation={[Math.PI / 2, 0, 0]}
          color="black"
          renderOrder={5}
        />
      </Suspense>
    </>
  );
}

import { Box, Button, Text, Image,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, } from "@chakra-ui/react"
import { useContext, useState } from "react";
import EditorContext from "../../context/EditorContext";

function DebugFooter() {
    const { file } = useContext(EditorContext);
    const [debugModalOpen, setDebugModalOpen] = useState(false);

    return (
        <Box
            flex="0 0 auto"
            height="40px"
            width="100%"
            backgroundColor="white"
            borderTop="2px solid rgba(0,0,0,0.1)"
            overflow="hidden"
            color="black"
            display="flex"
            alignItems="center"
            flexDir="row"
            shadow="lg"
            fontSize="12px"
            padding="5px"
            paddingLeft="15px"
            zIndex="4"
        >
            <Text
                flex="1"
                fontWeight="bold"
                fontSize="12px"
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                color="rgba(0,0,0,0.35)"
            >
                File: {file.name}, ID: {file.id}
            </Text>
            <Button flex="0 0 auto" size="sm" variant="outline" onClick={() => setDebugModalOpen(true)}>View JSON</Button>

            <Modal size="xl" isOpen={debugModalOpen} onClose={() => setDebugModalOpen(false)}>
                <ModalOverlay />
                <ModalContent>
                <ModalHeader>Current File Contents</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <Box borderTop="1px solid rgba(0,0,0,0.1)" mb="10px">
                        {JSON.stringify(file, null, 4)}
                    </Box>
                    <Button onClick={() => {
                          navigator.clipboard.writeText(JSON.stringify(file, null, 4));
                    }}>Copy to Clipboard</Button>
                </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    )
}

export default DebugFooter;
import React, { useRef, useMemo, useContext, createContext, useEffect } from "react";
import { useGLTF, Merged, useAnimations } from "@react-three/drei";



useGLTF.preload("/cornerRoom2.glb");

export function CornerRoom(props) {
  const { nodes, materials: roomMaterials } = useGLTF("/cornerRoom2.glb");

  const materials = useMemo(() => {
    if (!roomMaterials) {
      return null;
    }
    const newMaterials = {};
    Object.entries(roomMaterials).forEach(([materialKey, material]) => {
      newMaterials[materialKey] = material.clone();
      newMaterials[materialKey].depthWrite = true;
    });
    return newMaterials;
  }, [roomMaterials]);

  return (
    <group {...props} dispose={null}>
      <group position={[0.3, 1.4, 1.1]} rotation={[1.9, 0.9, -2]}>
        <pointLight
          intensity={4}
          decay={2}
          color="#ffa484"
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
      <group position={[-1, 1.5, -2]}>
        <pointLight
          intensity={4.2}
          decay={2}
          color="#ffc64c"
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
      <group position={[-0.8, 1.9, -2]}>
        <pointLight
          intensity={4.6}
          decay={2}
          color="#ffdb4d"
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
      <group position={[-2.1, 1.9, -1.1]}>
        <pointLight
          intensity={4}
          decay={2}
          color="#ffc64d"
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
      <group position={[-2.2, 0.9, -1.2]} rotation={[-0.9, 0.8, 0.5]}>
        <pointLight
          intensity={4}
          decay={2}
          color="#ff8917"
          rotation={[-Math.PI / 2, 0, 0]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane002.geometry}
        material={materials["Material.006"]}
        position={[-2.8, 1.5, 0.1]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[2.8, 2.8, 1.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane001.geometry}
        material={materials["Material.023"]}
        position={[-2.8, 1.5, 0.1]}
        rotation={[Math.PI / 2, 0, Math.PI / 2]}
        scale={[2.8, 2.8, 1.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane005.geometry}
        material={materials["Material.052"]}
        position={[-0.5, 1.5, -2.7]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[2.8, 1, 1.6]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.endtable.geometry}
        material={materials["Material.014"]}
        position={[-2.4, 0, -1.3]}
        scale={[1.2, 1.3, 1.2]}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.housecandle.geometry}
        material={materials["Material.017"]}
        position={[-2.2, 0.7, -1.2]}
        rotation={[0, -0.1, 0]}
        scale={1.3}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes["decortreeornaments1-2"].geometry}
        material={materials["Material.028"]}
        position={[-2.5, 0.7, -1.1]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.9}
      />
      <group
        position={[-2.5, 0.7, -1.1]}
        rotation={[0, Math.PI / 2, 0]}
        scale={0.9}
      >
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_1009.geometry}
          material={materials["Material.027"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_1009_1.geometry}
          material={materials["Material.026"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane.geometry}
        material={materials["Material.002"]}
        scale={2.8}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.Plane004.geometry}
        material={materials["Material.042"]}
        position={[-0.1, 3, 0]}
        scale={2.8}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.candlelight.geometry}
        material={materials["Material.048"]}
        position={[-1.9, 0.4, -1.1]}
        rotation={[-Math.PI, -0.3, -Math.PI]}
        scale={3.6}
      />
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.armchair.geometry}
        material={materials["Material.001"]}
        position={[-1.9, 0, -2]}
        rotation={[0, 0.6, 0]}
        scale={1.1}
      />
      <group position={[-2.4, 0, -0.3]} scale={[0.5, 1, 0.5]}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_0005.geometry}
          material={materials["Material.022"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_0005_1.geometry}
          material={materials["Material.018"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_0005_2.geometry}
          material={materials["Material.019"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_0005_3.geometry}
          material={materials["Material.021"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_0005_4.geometry}
          material={materials["Material.020"]}
        />
      </group>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.pillow1.geometry}
        material={materials["Material.035"]}
        position={[-1.8, 0.1, -2.2]}
        rotation={[0, 0.1, 0]}
        scale={1.1}
      />
      <group position={[-1, 0, -2.2]} rotation={[0, 0.2, 0]} scale={1.2}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001.geometry}
          material={nodes.s4studio_mesh_3001.material}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_1.geometry}
          material={materials["Material.029"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_2.geometry}
          material={materials["Material.030"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_3.geometry}
          material={materials["Material.032"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_4.geometry}
          material={materials["Material.033"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_5.geometry}
          material={materials["Material.003"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_6.geometry}
          material={materials["Material.004"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_7.geometry}
          material={materials["Material.025"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_8.geometry}
          material={materials["Material.005"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_3001_9.geometry}
          material={materials["Material.036"]}
        />
      </group>
      <group position={[-1.6, 0, -0.8]} scale={0.6}>
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_1019.geometry}
          material={materials["Material.050"]}
        />
        <mesh
          castShadow
          receiveShadow
          geometry={nodes.s4studio_mesh_1019_1.geometry}
          material={materials["Material.038"]}
        />
      </group>
    </group>
  );
}
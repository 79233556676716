import { useFBX, useGLTF } from "@react-three/drei";
import { useGraph } from "@react-three/fiber";
import { useMemo } from "react";
import { clone } from "three/examples/jsm/utils/SkeletonUtils.js";

export function useGLTFAndClone(path) {
  const { scene, materials, animations } = useGLTF(path);
  const clonedScene = useMemo(() => clone(scene), [scene]);
  const { nodes } = useGraph(clonedScene);

  return { scene: clonedScene, materials, animations, nodes };
}

export function useFBXAndClone(path) {
  const fbx = useFBX(path);
  console.log("old fbx", fbx);
  const clonedGroup = clone(fbx);
  clonedGroup.animations = fbx.animations;

  const clonedScene = useMemo(() => clonedGroup, [fbx]); //doesn't work so far

  return clonedScene;
}

// export function useFBXAndClone(path) {
//   const fbx = useFBX(path);
//   console.log("old fbx", fbx);
//   const newGroup = new Group();
//   fbx.children.map((child) => {
//     newGroup.add(clone(child));
//   });
//   const clonedScene = useMemo(() => newGroup, [fbx]); //doesn't work so far

//   return clonedScene;
// }

/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useMemo, useRef } from "react";
import { useGLTF, useAnimations } from "@react-three/drei";
import { BoxGeometry, Group, Mesh, MeshBasicMaterial } from "three";
import { useFrame } from "@react-three/fiber";
import { useGLTFAndClone } from "utils/useGLTFAndClone";

export function Elf({ path, ...restProps }) {
  const group = useRef();
  const {
    nodes: nodesOriginal,
    materials,
    animations,
  } = useGLTFAndClone("/elf2.glb");

  const { nodes: headNodes } = useGLTF(path);

  const nodes = useMemo(() => {
    const headGroup = new Group();
    const headMesh = new Mesh(headNodes.Head.geometry, headNodes.Head.material);

    const hairNode = headNodes.haircut_generated;
    //    headNodes.haircut_long_hair2;

    hairNode.material.transparent = true;

    console.log(headNodes);
    const haircutMesh = new Mesh(hairNode.geometry, hairNode.material);

    headGroup.add(headMesh);
    headGroup.add(haircutMesh);

    headGroup.scale.set(600, 600, 600);

    nodesOriginal.mixamorigHead.add(headGroup);
    headGroup.position.set(0, 20, 0);
    return nodesOriginal;
  }, []);

  const { actions, mixer } = useAnimations(animations, group);
  console.log("actions", actions);
  useEffect(() => {
    Object.values(actions)[0].play();
  }, [mixer]);
  console.log(nodes);

  return (
    <group ref={group} {...restProps} dispose={null}>
      <group name="Scene">
        <group name="Armature" rotation={[Math.PI / 2, 0, 0]} scale={0.01}>
          <primitive object={nodes.mixamorigHips} />
          <skinnedMesh
            name="Plane"
            geometry={nodes.Plane.geometry}
            material={materials["Material.001"]}
            skeleton={nodes.Plane.skeleton}
          />
        </group>
      </group>
    </group>
  );
}

useGLTF.preload("/elf2.glb");

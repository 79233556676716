import React, { useState } from "react";

import Lottie from "react-lottie";
import * as animationData from "../../assets/json/lotties/santa-swing.json";

import { Box, Text, keyframes } from "@chakra-ui/react";
import Snowfall from "react-snowfall";
import { useWindowSize } from "react-use";

const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice",
  },
};

const loadingDotsFrames = keyframes`
  25%   { content: '.'; }
  50%   { content: '..'; }
  75%  { content: '...'; }
`;

function LoadingPage() {
  const { height } = useWindowSize();
  const lottieHeight = 0.5 * height;
  const lottieWidth = lottieHeight;

  return (
    <Box
      flex="1"
      position="relative"
      display="flex"
      flexDir="column"
      justifyContent="flex-start"
      alignItems="center"
      backgroundColor="green"
      paddingBottom={"200px"}
      pos="absolute"
      width="100vw"
      top={0}
      left={0}
      right={0}
      bottom={0}
    >
      <Snowfall snowflakeCount={50} style={{ width: "100%", height: "100%" }} />
      <Lottie
        options={lottieOptions}
        height={lottieHeight}
        width={lottieWidth}
        isStopped={false}
        isPaused={false}
      />

      <Text
        color={"white"}
        fontSize="60px"
        position={"fixed"}
        top={"50%"}
        _after={{
          display: "inline-block",
          position: "absolute",
          content: '""',
          animation: `${loadingDotsFrames} 2s infinite`,
        }}
      >
        Loading
      </Text>
    </Box>
  );
}

export default LoadingPage;

import { Box } from "@chakra-ui/react";
import { useContext } from "react";
import EditorContext from "../context/EditorContext";
import DebugFooter from "./features/DebugFooter";
import LandingPage from "./features/LandingPage";
import Navbar from "./features/PrimaryNavbar";
import "@fontsource/mountains-of-christmas/700.css";
import LoadingPage from "./features/LoadingPage";
import React from "react";
import SharePage from "./features/SharePage";
import WebGLViewerTest from "./features/WebGLViewerTest";
import DerekWebGLViewerTest from "./features/DerekWebGLViewerTest";

function App() {
  const { file, tab, setTab } = useContext(EditorContext);

  return (
    <Box
      flex="1"
      display="flex"
      flexDir="column"
      justifyContent="stretch"
      alignItems="stretch"
      // overflow="scroll"
      bgColor="gray.100"
    >
      {/* <Navbar /> */}
      {/* <LandingPage /> */}
      {/* <LoadingPage /> */}
      {/* <SharePage /> */}
      <DerekWebGLViewerTest />
      {/* <WebGLViewerTest /> */}
      {/* /> */}
      {/* <DebugFooter /> */}
    </Box>
  );
}

export default App;

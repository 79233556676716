/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import { useAnimations, useGLTF } from "@react-three/drei";
import React, { useEffect, useRef } from "react";
import { Group, Mesh } from "three";
import { useFBXAndClone } from "utils/useGLTFAndClone";

export function ElfMixamo({ path, ...restProps }) {
  const { nodes: headNodes } = useGLTF(path);
  const fbx = useFBXAndClone("/elf.fbx");
  const twerkFbx = useFBXAndClone("/twerk-dance.fbx");

  useEffect(() => {
    fbx.traverse((child) => {
      if (child.name.includes("mixamorigNeck")) {
        console.log("found head", child);
        const headGroup = new Group();
        const headMesh = new Mesh(
          headNodes.Head.geometry,
          headNodes.Head.material
        );

        const hairNode = headNodes.haircut_generated;
        //    headNodes.haircut_long_hair2;

        hairNode.material.transparent = true;

        console.log(headNodes);
        const haircutMesh = new Mesh(hairNode.geometry, hairNode.material);

        headGroup.add(headMesh);
        headGroup.add(haircutMesh);

        headGroup.scale.set(600, 600, 600);

        child.add(headGroup);
        headGroup.position.set(0, 60, 0);
      } else {
        if (child.name.includes("Sphere")) {
          child.scale.set(0, 0, 0);
        }
      }
    });
  }, [fbx]);

  fbx.children[0].remove();

  console.log(fbx);

  const { actions, names } = useAnimations(twerkFbx.animations, fbx);

  useEffect(() => {
    actions[names[0]].play();
  });

  return (
    <group scale={0.011} {...restProps}>
      <primitive object={fbx} />
    </group>
  );
}

useGLTF.preload("/elf2.glb");

import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import { ChakraProvider } from "@chakra-ui/react";
import { EditorContextProvider } from "./context/EditorContext";
import App from "./components/App";
import chakraTheme from "./constants/chakraTheme";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    <ChakraProvider theme={chakraTheme}>
      <EditorContextProvider>
        <App />
      </EditorContextProvider>
    </ChakraProvider>
  </React.StrictMode>
);

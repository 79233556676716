import React, { useCallback, useRef, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import seagullFile from '../assets/json/seagull.json';

const EditorContext = React.createContext(null);
export default EditorContext;

export const TOOLS = {
  ADD: "ADD",
  SUBTRACT: "SUBTRACT",
  RECTANGLE_ADD: "RECTANGLE_ADD",
  RECTANGLE_SUBTRACT: "RECTANGLE_SUBTRACT"
};

const generateNewProject = () => {
  return {
    id: uuidv4(),
    name: "Untitled File",
    metadata: {},
    data: {},
    version: "1",
  };
};

export function EditorContextProvider({ children }) {
  const [file, setFile] = useState(seagullFile);
  const [tab, setTab] = useState(0);
  const [activeTool, setActiveTool] = useState(TOOLS.ADD);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [color, setColor] = useState("blue");

  const newProject = useCallback(() => {
    setFile(generateNewProject());
  }, []);

  const setData = useCallback((data) => {
    setFile({ ...file, data });
  }, []);

  return (
    <EditorContext.Provider
      value={{
        file,
        setFile,
        data: file.data,
        setData,
        tab,
        setTab,
        activeTool,
        setActiveTool,
        newProject,
        sidebarOpen,
        setSidebarOpen,
        color,
        setColor,
      }}
    >
      {children}
    </EditorContext.Provider>
  );
}
